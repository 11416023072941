.about {
  margin-bottom: 80px;
}
.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}
.about p {
  font-size: 18px;
  padding-right: 220px;
  margin-bottom: 20px;
  line-height: 30px;
}
.about .right img {
  width: 400px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 6px;
}
@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
  }
  .about p {
    padding: 0;
  }
  .about .container img {
    margin-top: 50px;
  }
}
@media screen and (max-width: 400px) {
  .about .right img {
    width: 100%;
  }
}
