.hero {
  background-image: url("../../../../public/images/banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  width: 100%;
  position: relative;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
  }

  h1 {
    text-shadow: 1px 0px 11px rgba(0, 0, 0, 0.9);
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 90vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.hero h1 {
  color: #ffffff;
  font-size: 40px;
}
.hero p {
  color: #fff;
  opacity: 0.8;
}
form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  padding: 0 20px;
}
form input {
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
}
form span {
  font-size: 14px;
  color: grey;
}
input::placeholder {
  font-size: 17px;
  color: black;
}
form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}
form .box:nth-child(1) {
  border-left: none;
}
form h4 {
  font-weight: 500;
}
#hero-text {
  z-index: 9999;
  width: 900px;
  text-align: center;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  #hero-text {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }
  form .box {
    border-left: none;
  }
  form input {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .hero h1 {
    font-size: 2em;
  }
}
@media screen and (max-width: 500px) {
  .hero h1 {
    font-size: 1.8em;
  }
}
@media screen and (max-width: 400px) {
  .hero h1 {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 300px) {
  .hero h1 {
    font-size: 1em;
  }
}
