header {
  position: sticky;
  top: 0;
  z-index: 99999;
  background-color: #f7f9fc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

.logo-wrapper {
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
  }
}

header span {
  width: auto;
  padding: 0px 6px;
  background: #27ae60;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}
header h4 {
  font-weight: 500;
  margin-right: 20px;
}
header .toggle {
  display: none;
}
header .toggle button i {
  width: 15px;
}
@media screen and (max-width: 768px) {
  header {
    padding: 0;
  }
  header .button {
    display: none;
  }
  header .nav .flex {
    display: none;
  }
  header .toggle {
    display: block;
    margin-left: 6vw;
  }
  header .small {
    position: absolute;
    top: 14.4vh;
    left: 0;
    width: 100%;
    background-color: #000000;
    padding: 20px;
    opacity: 0.9;
  }
  header .small li {
    padding: 10px 0;
  }
  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #d4d4d6;
  }
}

.button-menu {
  display: flex;
  justify-content: space-between;
}

.btn1 {
  width: 1vw;
  text-align: center;
  margin: 0 0.2vw;
  padding: 2vh 3vw;
  padding-left: 2.5vw;
}

.btn1 a {
  text-align: center;
  margin: 0;
  color: white;
}

#fb-button {
  padding-left: 2.6vw;
}

#main-nav-horizontal li a {
  margin: 0 2vw;
}

button {
  background: #323299;
}

.icons-wrapper {
  display: flex;
  justify-content: center;
  gap: 30px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
  }
}

@media screen and (max-width: 600px) {
  .icons-wrapper {
    gap: 20px;
  }
}

@media screen and (max-width: 600px) {
  .icons-wrapper {
    gap: 20px;
  }
}
@media screen and (max-width: 400px) {
  .icons-wrapper {
    gap: 10px;
  }
}